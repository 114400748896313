<i18n lang="json">
{
  "ru": {
    "reviewsTitle": "1 456 054 отзывов о проживании",
    "reviewsAll": "Посмотреть все отзывы",
    "reviewsFoto": "Содержит фото",
    "reviewsMore": "Читать отзыв",
    "user": "Пользователь",
    "lengthOfStay": "{n} суток | {n} сутки | {n} суток | {n} суток",
    "addingTime": {
      "0": "сегодня",
      "1": "вчера",
      "2": "позавчера",
      "3": "3 дня назад",
      "4": "4 дня назад",
      "5": "5 дней назад",
      "6": "6 дней назад",
      "7": "неделю назад",
      "8": "2 недели назад",
      "9": "3 недели назад",
      "10": "месяц назад"
    },
    "date": {
      "01": "Январь",
      "02": "Февраль",
      "03": "Март",
      "04": "Апрель",
      "05": "Май",
      "06": "Июнь",
      "07": "Июль",
      "08": "Август",
      "09": "Сентябрь",
      "10": "Октябрь",
      "11": "Ноябрь",
      "12": "Декабрь"
    }
  },
  "en": {
    "reviewsTitle": "1 456 054 accommodation reviews",
    "reviewsAll": "All reviews",
    "reviewsFoto": "Contains photo",
    "reviewsMore": "Read review",
    "user": "User",
    "lengthOfStay": "{n} days | {n} day | {n} days | {n} days",
    "addingTime": {
      "0": "today",
      "1": "yesterday",
      "2": "day before yesterday",
      "3": "3 days ago",
      "4": "4 days ago",
      "5": "5 days ago",
      "6": "6 days ago",
      "7": "a week ago",
      "8": "2 weeks ago",
      "9": "3 weeks ago",
      "10": "month ago"
    },
    "date": {
      "01": "January",
      "02": "February",
      "03": "March",
      "04": "April",
      "05": "May",
      "06": "June",
      "07": "July",
      "08": "August",
      "09": "September",
      "10": "October",
      "11": "November",
      "12": "December"
    }
  }
}
</i18n>

<template>
  <div v-if="reviews.length" class="reviews">
    <div class="reviews__top">
      <h3 class="heading-h3">{{ t("reviewsTitle") }}</h3>
    </div>
    <Splide :options="settings" class="hello" @move="refreshHightReview">
      <SplideSlide
        v-for="(item, index) in reviews"
        :key="item.review.id"
        class="review"
        :index="index"
        :class="{ height100Percent: showMore }"
      >
        <div
          class="review__header"
          target="_blank"
          :to="{
            name: 'Detail',
            params: { id: item.object.id },
          }"
        >
          <router-link
            target="_blank"
            :to="{
              name: 'Detail',
              params: { id: item.object.id },
            }"
            class="foto review__header-link"
          >
            <img
              v-if="!item.object.image.path.includes('default')"
              :src="item.object.image.path"
              alt=""
            />
            <img v-else alt="" src="~/assets/img/default-avatar_small.jpg" />
          </router-link>
          <div class="description">
            <router-link
              target="_blank"
              :to="{
                name: 'Detail',
                params: { id: item.object.id },
              }"
              class="review__header-link"
            >
              <div v-if="item.hotel">
                <div class="description__name regular-p6">
                  {{ item.hotel.type }} &laquo;{{ item.hotel.title }}&raquo;
                </div>
              </div>
              <div v-else>
                <div
                  v-if="item.object.type !== item.object.name"
                  class="description__name regular-p6"
                >
                  {{ item.object.type }}
                </div>
              </div>
            </router-link>
            <router-link
              target="_blank"
              :to="{
                name: 'Detail',
                params: { id: item.object.id },
              }"
              class="review__header-link"
            >
              <div class="description__text semibold-p5">
                {{ item.object.name }}
              </div>
            </router-link>
            <div class="description__city regular-p5">
              {{ item.object.location_title }}
            </div>
          </div>
        </div>
        <div class="review__body">
          <div
            class="text-review-wrap regular-p5"
            :class="{
              'text-review-wrap-open': findItemValue(item.review.id),
            }"
          >
            <div
              class="text-review"
              :class="{ 'text-review-open': findItemValue(item.review.id) }"
            >
              {{ item.review.text }}
            </div>
            <span
              v-if="item.review.text.length > 267"
              class="show-else"
              @click="openText(item.review.id)"
            >
              {{ findItemValue(item.review.id) ? "скрыть" : "ещё..." }}
            </span>
          </div>
        </div>
        <div class="review__footer">
          <div class="footer-wrapper">
            <div
              class="review__footer-left"
              :style="`background-color:${randomColor(item.guest.name)}`"
            >
              <img
                v-if="item.guest.image.path"
                class="review__footer-img_default"
                :src="item.guest.image.path"
              />
            </div>
            <div class="review__footer-content">
              <div class="review__footer-content-title semibold-p5">
                {{ getName(item.guest.name ) }}
              </div>
              <div class="review__footer-booking-desc regular-p6">
                <span class="review__footer-guests">
                  {{ item.review.guests }} гостя,&nbsp;
                </span>
                <span class="review__footer-days">
                  {{ t("lengthOfStay", item.review.days) }},&nbsp;
                </span>
                <span class="review__footer-date-of-stay" v-if="viewPort !== 'mobile'">
                  {{ dateStart(item.review.date_begin) }}
                </span>
              </div>
            </div>
          </div>
          <div class="raiting-wrap">
            <Rating
              :count-reviews="Number(Number(item.review.rating).toFixed(1))"
              :data-rating="Number(item.review.rating) * 2"
              :view-count="false"
              :is-small="true"
              :is-external-rating="true"
              :text-bold="true"
            />
          </div>
        </div>
      </SplideSlide>
    </Splide>
    <a v-if="viewPort !== 'mobile'" class="reviews__link medium-p4" href="/votes">{{ t("reviewsAll") }}</a>
  </div>
</template>

<script>
import _ from "lodash";
import { Reviews } from "@/components/api/";
import Rating from "../BaseRating";
import { formatSafari } from "@/utils/format";

// TODO: замена с vue-slick-carousel на https://splidejs.com/integration/vue-splide/
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { mapState } from 'vuex';
import { useI18n } from 'vue-i18n';

export default {
  name: "BaseReviews",
  components: {
    Rating,
    Splide,
    SplideSlide,
  },
  setup() {
    const { t } = useI18n();
    return {
      t
    }
  },
  data() {
    return {
      settings: {
        pagination: false,
        arrows: false,
        type: "loop",
        speed: 500,
        perPage: 3,
        padding: "15%",
        gap: 16,
        breakpoints: {
          768: {
            perPage: 1,
          },
          1280: {
            perPage: 2,
          },
          1485: {
            padding: "10%",
          },
        },
      },
      reviews: [],
      showMore: false,
      itemsBtnOpenText: [],
    };
  },
  computed:{
    ...mapState("user", ["viewPort"])
  },
  mounted() {
    this.getReviewsGoodList();
  },
  methods: {
    getName(name){
      if (this.viewPort == "mobile") {
        return name.split(" ")[0];
      } 
      return name;
    },
    getReviewsGoodList() {
      Reviews.getReviewsGoodList()
        .then((response) => {
          if (response.data && response.data.data) {
            const reviews = response.data.data.reviews;
            this.reviews = reviews;
            this.reviews.forEach((it) =>
              this.itemsBtnOpenText.push({
                id: it.review.id,
                value: false,
              })
            );
          }
        })
        .catch((error) => {
          console.warn("Ajax error: ", error);
        });
    },
    findItemValue(id) {
      return this.itemsBtnOpenText.find((it) => it.id === id).value;
    },
    dateStart(val) {
      if (val) {
        let dateArr = val.includes("-") ? val.split("-") : val.split(".");
        if (val.includes("-")) {
          return this.t(`date.${dateArr[1]}`) + " " + dateArr[0];
        } else {
          return this.t(`date.${dateArr[1]}`) + " " + dateArr[2];
        }
      }
      return false;
    },
    toObject(id) {
      this.$router.push(`/detail/${id}`);
    },
    refreshHightReview() {
      this.showMore = false;
      this.itemsBtnOpenText.forEach((it) => (it.value = false));
    },
    getAddingTime(dateAdd) {
      if (isNaN(Date.parse(formatSafari(String(dateAdd))))) return false;
      const date_add = new Date(formatSafari(String(dateAdd)));
      const addingDate = new Date(
        date_add.getFullYear(),
        date_add.getMonth(),
        date_add.getDate()
      );
      const date = new Date();
      const currentDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      const passedDays = (currentDate - addingDate) / 86400000;
      if (passedDays < 1) return this.t("addingTime.0");
      else if (passedDays === 1) return this.t("addingTime.1");
      else if (passedDays === 2) return this.t("addingTime.2");
      else if (passedDays === 3) return this.t("addingTime.3");
      else if (passedDays === 4) return this.t("addingTime.4");
      else if (passedDays === 5) return this.t("addingTime.5");
      else if (passedDays === 6) return this.t("addingTime.6");
      else if (passedDays >= 7 && passedDays <= 13)
        return this.t("addingTime.7");
      else if (passedDays >= 14 && passedDays <= 20)
        return this.t("addingTime.8");
      else if (passedDays >= 21 && passedDays <= 27)
        return this.t("addingTime.9");
      else if (passedDays >= 28 && passedDays <= 38)
        return this.t("addingTime.10");
      else return this.dateComment(dateAdd);
    },
    openText(id) {
      this.itemsBtnOpenText.every((it) => it.value === false)
        ? (this.showMore = true)
        : (this.showMore = false);
      this.itemsBtnOpenText = this.itemsBtnOpenText.map((it) => ({
        ...it,
        value: it.id === id ? !it.value : it.value,
      }));
    },
    dateComment(dateAdd) {
      let date = new Date(formatSafari(String(dateAdd)));
      let formatter = new Intl.DateTimeFormat(this.$i18n.locale, {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      formatter = formatter.format(date);
      return formatter;
    },
    randomColor(name) {
      if (name.length < 5) return "#7FFFD4";
      if (name.length > 4 && name.length < 9) return "#FF7F50";
      return "#B0C4DE";
    },
  },
};
</script>

<style lang="scss" scoped>
:deep() {
  // Default theme
  @import "@splidejs/vue-splide/css";
  // or only core styles
  @import "@splidejs/vue-splide/css/core";
}
:deep() {
  @media screen and (max-width: 768px) {
    .review__footer-booking-desc {
      font-size: 12px;
    }
  }
}
.reviews {
  margin-top: 80px;
  @media (max-width: 425px) {
    margin-top: 50px;
  }

  &__link {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background: var(--color-gray-g3);
    width: max-content;
    margin-top: 29px;
    padding: 15px 24px;
    color: var(--color-main-black);
    border-radius: 14px;
  }

  &__top {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 39px;

    @media all and (max-width: 640px) {
      font-size: 13px;
    }
    @media all and (max-width: 576px){
      margin-bottom: 20px;
    }

    h3 {
      padding: 0;
      margin: 0;
      color: var(--color-main-black);
      @media all and (max-width: 576px)  {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }
    a {
      color: #2d6cb4;
      &:hover {
        color: #f51449;
      }
    }
  }
}

:deep() {
  .splide {
    &__arrow {
      display: flex !important;
      align-items: center;
      height: 40px;
      width: 40px;
      background-color: white;
      border-radius: 20px;
      cursor: pointer;
      transition: all 0.3s;

      &::before {
        content: "";
        display: block;
        border-right: 2px solid black;
        border-bottom: 2px solid black;
        border-radius: 2px;
        height: 10px;
        width: 10px;
      }

      &:hover {
        opacity: 0.5;
      }
      &--next {
        right: -57px;

        &::before {
          transform: rotate(-45deg);
          margin-right: 2px;
        }
      }
      &--prev {
        left: -57px;

        &::before {
          transform: rotate(135deg);
          margin-left: 2px;
        }
      }

      svg {
        display: none;
      }
    }
    &__pagination {
      bottom: -25px;
      &__page {
        height: 20px;
        width: 20px;
        padding: 5px;
        background: none;
        opacity: 1;

        &:before {
          color: #000;
          content: "•";
          font-family: slick;
          font-size: 20px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          height: 20px;
          left: 0;
          line-height: 20px;
          opacity: 0.25;
          position: absolute;
          text-align: center;
          top: 0;
          width: 20px;
        }
        &.is-active {
          background: transparent;
          transform: scale(1);
          &::before {
            opacity: 0.75;
          }
        }
      }
    }
  }

  .review {
    background: #fff;
    padding: 16px;
    font-size: 13px;
    height: 100%;
    display: flex !important;
    flex-direction: column;
    border-radius: 20px;
    box-shadow: 0px 1px 5px rgba(20, 45, 136, 0.02),
      0px 5px 10px rgba(20, 45, 136, 0.02);

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;

      &-link {
        transition: 0.3s;
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }

        
      }

      .foto {
        border-radius: 5px;
        height: 100px;
        width: 130px;
        margin-right: 14px;
        @media all and (max-width: 576px){
          width: 80px;
          height: 80px;
          flex-shrink: 0;
        }

        img {
          border-radius: 5px;
          height: 100%;
          width: 100%;
        }
      }

      .description {
        height: 82px;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__name {
          margin-bottom: 2px;
          color: var(--color-gray-g5);
          @media all and (max-width: 576px){
            margin-bottom: 4px;
            font-size: 12px;
            line-height: 16px;
          }
        }

        &__text {
          margin-bottom: 7px;
          color: var(--color-main-black);
          @media all and (max-width: 576px){
            margin-bottom: 8px;
            font-size: 12px;
            line-height: 16px;
          }
        }

        &__city {
          color: var(--color-gray-g5);
          white-space: nowrap;
          @media all and (max-width: 576px){
            font-size: 12px;
            line-height: 16px;
          }
        }
      }
    }

    &__body {
      height: auto;
      margin-bottom: 25px;

      .text-review-wrap {
        height: 60px;
      }

      .text-review-wrap-open {
        height: auto !important;
      }

      .text-review {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        @media all and (max-width: 576px){
          font-size: 12px;
          line-height: 16px;
        }
      }

      .text-review-open {
        display: block !important;
      }
    }

    &__footer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .footer-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media all and (max-width: 576px){
          width: 70%;
        }
      }
    }
  }

  .height100Percent {
    height: 100% !important;
  }
}

.raiting-wrap {
  padding: 5px 10px;
  background: var(--color-gray-g2);
  border-radius: 6px;
  @media all and (max-width: 576px){
    background: rgba(245, 20, 73, 0.05)
  }

  :deep(.rating-list__rating) {
    font-size: 14px;
    line-height: 20px;
    @media all and (max-width: 576px){
      color: #F51449
    }
  }

  :deep(.icon-app-star) {
    display: flex;
    align-items: center;
  }
}

.comment-date {
  color: #717171;
}

.review__footer {
  &-left {
    width: 38px;
    height: 38px;
    min-width: 38px;
    border-radius: 20px;
  }

  &-img {
    display: block;
    height: 38px;
    width: 100%;
    background: #d8d8d8;
    border-radius: 50%;
    object-fit: cover;
  }

  &-img_default {
    display: block;
    height: 38px;
    border-radius: 20px;
    width: 100%;
  }

  &-content {
    width: calc(100% - 55px);
    margin-left: 12px;
    flex-grow: 1;
    position: relative;

    &-title {
      margin-bottom: 2px;
      @media all and (max-width: 576px){
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &-booking-desc {
    display: flex;
    color: var(--color-gray-g5);
  }

  &-icon {
    margin-right: 5px;
    font-size: 16px;
  }
}
.show-else {
  color: #2d6cb4;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    opacity: 0.5;
  }
}
</style>
