<template>
  <div>
    <Header />
    <MainWrapper />
    <Footer />
  </div>
</template>

<script>
import { Header } from "@sutochno/header";
// import { Header } from "@/components/Header";
import MainWrapper from "@/components/desktop/Main/";
import { Footer } from "@sutochno/footer";
import { Main } from "@/utils/gtm/";
import useMetaMain from '@/composables/meta/main';

export default {
  components: {
    Header,
    MainWrapper,
    Footer,
  },
  setup() {
    useMetaMain();
  },
  mounted() {
    Main.gtmDataPage();
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (min-width: 1280px) {
  :deep(.sc-footer-container) {
    width: 1180px !important;
  }
}
</style>
