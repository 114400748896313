<template>
  <div class="main">
    <div class="container">
      <TitleMain />
      <SearchWidget />

      <GetCashback />
      <BrieflyAboutUs />
      <RouteDirection />
      <WhereToGo />
      <BookInApp />
    </div>

    <!-- <CountReviewsAccommodation /> -->

    <div class="container">
      <PopularAbroad />
      <WhereToBegin />
    </div>
  </div>
</template>

<script>
import TitleMain from "./TitleMain.vue";
import RouteDirection from "./RouteDirection.vue";
import BrieflyAboutUs from "./BrieflyAboutUs.vue";
import GetCashback from "./GetCashback.vue";
import WhereToGo from "./WhereToGo.vue";
import BookInApp from "./BookInApp.vue";
import CountReviewsAccommodation from "./CountReviewsAccommodation.vue";
import PopularAbroad from "./PopularAbroad.vue";
import WhereToBegin from "./WhereToBegin.vue";
import SearchWidget from "./SearchWidget.vue";

export default {
  components: {
    TitleMain,
    RouteDirection,
    BrieflyAboutUs,
    GetCashback,
    WhereToGo,
    BookInApp,
    CountReviewsAccommodation,
    PopularAbroad,
    WhereToBegin,
    SearchWidget,
  },
};
</script>

<style lang="scss" scoped>
.main {
  .container {
    max-width: 1160px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;

    @media (max-width: 1160px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
</style>
