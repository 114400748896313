<i18n lang="json">
{
  "ru": {
    "leftTitle1": "В отпуск намылились?",
    "rightTitle": "В приложении \n больше!",
    "heading": "Получайте кэшбэк бонусами \n до 30% от общей стоимости \n проживания.",
    "description": "Максимальный кэшбэк в мобильном \n приложении Суточно.ру!",
    "moreBtn": "Узнать о кэшбэке",
    "withJacuzzi": "Квартиры с джакузи",
    "withPool": "Жильё с бассейном",
    "mountainView": "С видом на горы",
    "glamplings": "Глэмпинги Карелии",
    "forParty": "Коттеджи для вечеринки",
    "withBreakfast": "Отели с завтраком",
    "download": "Установить приложение"
  },
  "en": {
    "leftTitle1": "Are you going on vacation?",
    "rightTitle": "More in the app!",
    "heading": "Get cashback up to 30% \n of the total cost of your stay.",
    "description": "Maximum cashback \n in the mobile application \n Sutochno.ru!",
    "moreBtn": "Learn about cashback",
    "withJacuzzi": "Apartments with Jacuzzi",
    "withPool": "Housing with a pool",
    "mountainView": "Mountain view",
    "glamplings": "Glampings of Karelia",
    "forParty": "Party Cottages",
    "withBreakfast": "Hotels with breakfast",
    "download": "Download application"
  }
}
</i18n>

<template>
  <div class="get-cashback">
    <div class="get-cashback__left">
      <p class="get-cashback__left-title heading-h3">
        {{ t("leftTitle1") }}
      </p>
      <div class="get-cashback__labels">
        <a
          :href="label.link"
          target="_blank"
          v-for="(label, i) in labels"
          :key="i"
          class="medium-p6"
        >
          {{ t(`${label.textCode}`) }}
        </a>
      </div>
    </div>
    <div class="get-cashback__right">
      <div class="get-cashback__group">
        <p class="get-cashback__right-title heading-h3">
          {{ t("rightTitle") }}
        </p>
        <p class="get-cashback__heading heading-h7">{{ t("heading") }}</p>
        <p class="get-cashback__description regular-p6">
          {{ t("description") }}
        </p>
      </div>
      <span @click="showCashbackModal" class="get-cashback__more semibold-p8">
        {{ t("moreBtn") }}
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.41602 11.8108L10.416 8.81079L7.41602 5.81079"
            stroke="white"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <!-- Поставить актуальную ссылку и подставить класс для кнопки из UI Kit (сейчас стили в scoped) -->
      <UIButton buttonTheme="red" @click="openApp">
        {{ t("download") }}
      </UIButton>
    </div>

    <AppInfo v-model="viewAppInfo"/>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { event as sendEventGtag } from "@/composables/gtag";

import UIButton from "@/components/UIKit/UIButton.vue";
import AppInfo from "./AppInfo.vue";
import { ref } from "vue";

const { t } = useI18n();
const store = useStore();
// Поменять все ссылки на актуальные
const labels = [
  {
    textCode: "withJacuzzi",
    link: "/front/searchapp/search?is_apartment=1&jacuzzi=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426",
  },
  {
    textCode: "withPool",
    link: "/front/searchapp/search?pool=1&from=mainpage&guests_adults=2&id=287363&type=city&term=%D0%93%D0%B5%D0%BB%D0%B5%D0%BD%D0%B4%D0%B6%D0%B8%D0%BA&SW.lat=44.533968&SW.lng=37.968957&NE.lat=44.614525&NE.lng=38.134193)",
  },
  {
    textCode: "mountainView",
    link: "/front/searchapp/search?view_mountains=1&from=mainpage&guests_adults=2&id=410738&type=city&term=%D0%90%D1%80%D1%85%D1%8B%D0%B7&SW.lat=43.509017&SW.lng=41.169035&NE.lat=43.584127&NE.lng=41.381406 )",
  },
  {
    textCode: "glamplings",
    link: "/front/searchapp/search?is_gl_rec=1&from=mainpage&guests_adults=2&id=19820&type=region&term=%D1%80%D0%B5%D1%81%D0%BF%D1%83%D0%B1%D0%BB%D0%B8%D0%BA%D0%B0+%D0%9A%D0%B0%D1%80%D0%B5%D0%BB%D0%B8%D1%8F&SW.lat=60.679619&SW.lng=29.306117&NE.lat=66.668641&NE.lng=37.946517) ",
  },
  {
    textCode: "forParty",
    link: "/front/searchapp/search?is_house=1&party=1&from=mainpage&guests_adults=2&id=1&term=%D0%A0%D0%BE%D1%81%D1%81%D0%B8%D1%8F+%D0%9C%D0%BE%D1%81%D0%BA%D0%BE%D0%B2%D1%81%D0%BA%D0%B0%D1%8F+%D0%BE%D0%B1%D0%BB%D0%B0%D1%81%D1%82%D1%8C&SW.lat=54.255677&SW.lng=35.148867&NE.lat=56.958395&NE.lng=40.204864)",
  },
  {
    textCode: "withBreakfast",
    link: "/front/searchapp/search?is_hotel=1&has_breakfast=1&from=mainpage&guests_adults=2&id=397367&type=city&term=%D0%A1%D0%B0%D0%BD%D0%BA%D1%82-%D0%9F%D0%B5%D1%82%D0%B5%D1%80%D0%B1%D1%83%D1%80%D0%B3&SW.lat=59.744311&SW.lng=30.043373&NE.lat=60.090935&NE.lng=30.566426)",
  },
];

const showCashbackModal = () => {
  sendEventGtag("modal_cashback", {
    click: "about_cashback",
  });
  store.commit("user/setShowCashbackModal", true);
};

const viewAppInfo = ref(false)
function openApp(){
  viewAppInfo.value = true
}
</script>

<style lang="scss" scoped>
.get-cashback {
  margin-top: 43px;
  display: flex;
  gap: 16px;

  &__modal {
    .modal {
      &__header {
        border-radius: 20px 0px 0px 0px;
        height: 165px;
        background-image: url("@/assets/img/main/cashback-modal-header-bg.png");
        background-repeat: no-repeat;
        background-color: #E7E9F3;
        background-position-x: 100%;
        background-position-y: 95%;
        background-size: 75%;
        padding: 39px 41px;

        .header {
          &__heading {
            color: var(--color-main-black);
            margin-bottom: 9px;
          }
          &__text {
            color: var(--color-gray-g5);
            margin: 0;
          }
        }
      }

      &__content {
        padding: 10px 30px 0px 30px;
        overflow: auto;
        .content {
          &__heading {
            font-weight: 600;
            font-size: 26px;
            line-height: 30px;
            color: #1c1c1c;
          }
          &__list-heading {
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            display: flex;
            align-items: center;
            color: #1c1c1c;
            margin-top: 26px;
            margin-bottom: 17px;
          }
          &__list {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin: 0;
            padding: 0;
          }
          &__list-item {
            display: flex;
            align-items: center;
            gap: 15px;
            .list-item {
              &__step {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 50px;
                height: 50px;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #5a5d63;
                background-color: #f1f3fb;
                border-radius: 50%;
              }
              &__link {
                color: var(--color-accent);
              }
              &__text {
                flex-basis: 427px;
                margin: 0;
                font-weight: 400;
                font-size: 13px;
                line-height: 19px;
                color: #5a5d63;
              }
            }
          }

          &__dropdown {
            margin: 0;
            padding: 0;
            .dropdown {
              &__item {
                display: grid;
                grid-template-columns: 22px 1fr;
                border-top: 1px solid #f1f3fb;
                padding: 15px 0;
                cursor: pointer;
              }
              &__item-icon {
                color: #1c1c1c;
                font-weight: 900;
                font-size: 15px;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                transition: all 0.3s;
              }
              &__item-heading {
                font-weight: 600;
                font-size: 18px;
                color: #1c1c1c;
                margin: 0;
              }
              &__item-text {
              }
            }
          }
        }
      }

      &__footer {
        display: flex;
        padding: 30px 30px 10px 30px;
        border-top: 1px solid #f1f3fb;
        justify-content: space-between;
        .footer {
          &__button {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding: 15px 48px;
            border: none;
            outline: none;
            border-radius: 14px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            &_secondary {
              background: #f1f3fb;
              color: #1c1c1c;
            }
            &_primary {
              background: #f51449;
              color: #ffffff;
              width: 100%;
            }
          }
          &__button-icon {
            font-size: 16px;
            margin-right: 11px;
          }
        }
      }
    }
  }

  &__left-title {
    color: var(--color-white);
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15),
      0px 4px 10px rgba(0, 0, 0, 0.2);
  }

  &__group {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__heading {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__description {
    color: var(--color-gray-g2);
    margin: 0;
    white-space: pre-line;
  }

  &__labels {
    margin-top: 20px;
    display: flex;
    flex-flow: row wrap;
    gap: 8px;

    a {
      color: var(--color-white);
      padding: 7px 14px;
      background: rgba(28, 28, 28, 0.4);
      backdrop-filter: blur(10px);
      border-radius: 20px;
    }
  }

  &__left {
    width: 50%;
    height: 423px;
    border-radius: 30px;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 37.14%,
        rgba(0, 0, 0, 0.4) 100%
      ),
      url("@/assets/img/main/main-left.jpg"), #d9d9d9;
    padding: 40px 39px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__right-title {
    white-space: pre-line;
    color: var(--color-white);
    margin: 0;
  }

  &__right {
    width: 50%;
    height: 423px;
    background-image: url("@/assets/img/main/main-right.jpg");
    background-color: var(--color-gray-g3);
    background-repeat: no-repeat;
    border-radius: 30px;
    padding: 34px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-position: center center;
    background-size: cover;

    button{
      margin-top: 24px;
      max-width: 260px;
    }
  }

  &__more {
    margin-top: 33px;
    color: var(--color-white);
    display: inline-flex;
    align-items: center;
    width: max-content;
    cursor: pointer;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

.modal__content::-webkit-scrollbar {
  width: 8px;
}

.modal__content::-webkit-scrollbar-track {
  background: white;
}

.modal__content::-webkit-scrollbar-thumb {
  background-color: #5a5d63;
  border-radius: 20px;
}

.dropdown__item-icon::before {
  transition: all 0.3s;
  transform: rotateX(180deg);
}

.rotate-icon::before {
  transform: rotateX(0);
}
</style>
